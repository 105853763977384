<template>
  <div>
    <button
      @click="check()"
      v-permissions="'Add Shipment Type'"
      target="_blank"
      :disabled="processing"
      class="orange-btn"
    >
      + Add New
    </button>
    <v-dialog
      v-model="dialog"
      style="border-radius: 10px !important"
      max-width="57%"
      persistent
    >
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title
            style="
              background-color: #f5f5f5;
              padding-bottom: 5px;
              padding-top: 5px;
            "
          >
            <span style="font-weight: 700" class="headline"
              >Add New Shipment Document</span
            >
            <v-spacer></v-spacer>
            <v-btn :disabled="processing" icon @click.prevent="closeDialog()">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-card-title>
          <v-progress-linear
            indeterminate
            color="yellow darken-2"
            v-show="processing"
          ></v-progress-linear>
          <v-spacer class="spacerclass">
            <v-card-text>
              <v-container grid-list-md>
                <v-layout pt-4>
                  <v-flex xs6 mr-3>
                    <span>Shipment Type</span>
                    <v-text-field
                      label="Enter shipment type"
                      v-model="documentName"
                      :rules="[rules.noWhiteSpace, rules.required]"
                      single-line
                      class="pt-0 currencyTitle"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout pt-4>
                  <v-flex xs3 mr-3>
                    <span>Job Type</span>
                    <v-autocomplete
                      :items="shipmentTypes"
                      v-model="shipmentType"
                      item-text="text"
                      item-value="value"
                      class="pt-0 currencyTitle"
                      label="Select"
                      required
                      single-line
                      :rules="[rules.required]"
                    />
                  </v-flex>
                  <v-flex xs3 mr-3>
                    <span>Job Booking Type</span>
                    <v-select
                      :items="jobBookingTypes"
                      v-model="jobBookingType"
                      item-text="text"
                      item-value="value"
                      class="pt-0 currencyTitle"
                      label="Select"
                      multiple
                      single-line
                      :rules="[rules.requiredLength]"
                    />
                  </v-flex>
                  <v-flex xs3>
                    <span>Geography</span>
                    <v-select
                      :items="jobGeoTypes"
                      v-model="jobGeoType"
                      item-text="text"
                      item-value="value"
                      class="pt-0 currencyTitle"
                      @change="jobModeEmpty()"
                      label="Select"
                      multiple
                      single-line
                      :rules="[rules.requiredLength]"
                    />
                  </v-flex>
                </v-layout>
                <v-layout pt-4>
                  <v-flex xs3 mr-3 v-if="jobGeoType == '1'">
                    <span>Job Mode</span>
                    <v-select
                      :items="jobModeInternational"
                      v-model="jobMode"
                      :disabled="!this.jobGeoType.length"
                      item-text="text"
                      item-value="value"
                      class="pt-0 currencyTitle"
                      label="Select"
                      multiple
                      single-line
                      :rules="[rules.requiredLength]"
                    />
                  </v-flex>
                  <v-flex xs3 mr-3 v-else>
                    <span>Job Mode</span>

                    <v-select
                      :items="jobModes"
                      v-model="jobMode"
                      :disabled="!this.jobGeoType.length"
                      item-text="text"
                      item-value="value"
                      class="pt-0 currencyTitle"
                      label="Select"
                      multiple
                      single-line
                      :rules="[rules.requiredLength]"
                    />
                  </v-flex>
                  <v-flex xs3 mr-3>
                    <span>Stakeholders</span>
                    <v-select
                      v-model="stakeholder"
                      label="Select"
                      item-text="text"
                      item-value="value"
                      :items="stakeholders"
                      class="pt-0 currencyTitle"
                      :rules="[rules.requiredLength]"
                      multiple
                      single-line
                    />
                  </v-flex>
                  <v-flex xs3>
                    <span>Working Country</span>
                    <v-select
                      v-model="countrySortName"
                      label="Select"
                      class="pt-0 currencyTitle"
                      :items="workingCountries"
                      item-text="value"
                      item-value="value"
                      multiple
                      :rules="[rules.requiredLength]"
                      single-line
                    />
                  </v-flex>
                </v-layout>
              </v-container>
              <!-- <small class="success" v-show="this.res">{{ this.res }}</small> -->
            </v-card-text>
            <v-card-actions style="padding-bottom: 30px">
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                flat
                @click="closeDialog()"
                :disabled="processing"
                >Close</v-btn
              >
              <v-btn
                color="orange darken-1"
                :disabled="processing"
                flat
                @click="add()"
                >Add</v-btn
              >
            </v-card-actions>
          </v-spacer>
        </v-card>
      </v-form>
      <!-- <ErrorBox :error="x.error" /> -->
      <v-snackbar
        :timeout="3000"
        bottom
        color="red darken-2"
        v-model="x.error"
        class="white--text"
        v-if="x.error"
        >{{ x.error }}</v-snackbar
      >
    </v-dialog>
  </div>
</template>
<script>
import {
  StorageKeys,
  JobTypes,
  JobGeoType1,
  JobBookingType1,
  StakeHolders,
  JobMode1,
  WorkingCountries,
  JobModeInternational,
} from "../../constants/constants";
import ErrorBox from "@/components/Common/ErrorBox";
import { salesSectionApiUrl } from "../../constants/api-urls";
export default {
  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
    }

    this.shipmentTypes = JobTypes;
    this.jobGeoTypes = JobGeoType1;
    this.jobModes = JobMode1;
    this.stakeholders = StakeHolders;
    this.jobBookingTypes = JobBookingType1;
    this.jobModeInternational = JobModeInternational;
  },
  data() {
    return {
      checkbox1: false,
      shipmentTypes: [],
      documentName: "",
      countrySortName: [],
      multipleCountry: [
        { value: "KSA", currency: "SAR" },
        { value: "UAE", currency: "AED" },
        { value: "PAK", currency: "PKR" },
        { value: "UAE LA", currency: "AED" },
      ],
      jobBookingTypes: [],
      stakeholders: [],
      jobModeInternational: [],
      jobModes: [],
      jobGeoTypes: [],
      valid: true,
      shipmentType: "",
      jobMode: [],
      stakeholder: [],
      jobBookingType: [],
      jobGeoType: [],
      lazy: false,
      weight: "",
      index: 0,
      invoiceNumber: "",
      invoiceValue: "",
      shipmentDocument: [
        {
          i: 0,
          document: [],
          documentNumber: "",
        },
      ],
      res: "",
      dialog: false,
      selected: ["email"],
      message: null,
      loading: false,
      processing: false,
      x: {
        error: "",
      },
      rules: {
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "This field is required.",
        required: (value) => !!value || "This field is required.",
        requiredLength: (value) => !!value.length || "This field is required.",
      },
    };
  },
  components: {
    ErrorBox,
  },
  props: [],
  methods: {
    async check() {
      this.processing = true;
      this.operationName = "Add Shipment Type";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.processing = false;
        this.dialog = true;
      } else {
        this.processing = false;
        return;
      }
    },
    jobModeEmpty() {
      this.jobMode = [];
    },
    closeDialog() {
      this.dialog = false;
      (this.documentName = ""),
        (this.shipmentType = ""),
        (this.shipmentType = ""),
        (this.jobMode = []),
        (this.stakeholder = []),
        (this.jobBookingType = []),
        (this.jobGeoType = []),
        (this.countrySortName = []),
        this.$refs.form.resetValidation();
    },
    add() {
      if (this.$refs.form.validate()) {
        this.processing = true;
        let url = salesSectionApiUrl.addShipmentType;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = {
          documentName: this.documentName,
          jobType: this.shipmentType,
          jobGeoType: this.jobGeoType,
          bookingType: this.jobBookingType,
          stakeHolder: this.stakeholder,
          jobMode: this.jobMode,
          country: this.countrySortName,
        };
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.res = response.data.message;
            this.closeDialog();
            this.$eventBus.$emit("add-shipment-doc");
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.x.error = error.response.data.message;
          }
        );
      }
    },
  },
};
</script>
<style scoped>
.orange-btn {
  text-decoration: none;
  color: #ffffff;
  border-radius: 4px;
  background-color: darkorange;
  border: 2px solid darkorange;
  padding: 10px 15px;
}
.message {
  display: inline-block;
  margin: 0 50px;
}
.btn-wrapper {
  border: 2px solid red;
  color: red;
  background-color: white;
  padding: 8px 20px;
  cursor: pointer !important;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
}
.list {
  display: inline-block;
  padding: 10px;
}
</style>
